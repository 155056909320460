<template>
  <div class="flex items-left flex-col ml-2 mt-4">
    <a href="https://forms.office.com/r/FLws4nn6Te" target="__blank" class="flex items-center">
      <div class="card-image flex items-center justify-center h-40">
        <font-awesome-icon class="fa-2x text-blue-500" :icon="['fas', 'link']" />
      </div>
      <h3 class="text-4xl font-bold ml-2 text-left w-full text-blue-500">{{ title }}</h3>
    </a>
  </div>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  name: 'HeaderLinkComponent',
  props: {
    title: String,
    subtitle: String,
  },
  components: {
    FontAwesomeIcon,
  },
}
</script>

<style scoped>
.hero-body {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.title,
.subtitle {
  text-align: center;
}

.hr {
  background-color: #E6CE9E;
  margin: 0;
  height: 5px;
}
</style>
